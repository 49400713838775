import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Stack,
  TextField
} from '@mui/material';
import AutoComplete from '../../Common/AutoComplete/AutoComplete';
import Selector from '../../Common/Selector/Selector';
import CheckBox from '../../Common/CheckBox/CheckBox';
import Input from '../../Common/Input/Input';
import BorderButton from '../../Common/Buttons/BorderButton';
import OutlineButton from '../../Common/OutlineButton/Button';
import { Container } from './EditStyles';


const EditModal = ({
  queryValues,
  open,
  handleValueChange,
  handleCloseQueryModdal,  
  onCancel,
  onSave,
  QUERY_VALUES
}) => {

  const [isDisabled, setDisabled] = useState(true);

  const CheckValidate = (template, field, value) => {
    let isValidated = true;

    if (queryValues[template][field]){
      queryValues[template][field].value = value;
    } else {      
      queryValues[template][field] = QUERY_VALUES[template][field];
      queryValues[template][field].value = value;
    }   

    let countTemplate = 0
    Object.keys(queryValues).map((template) => {
      if (template == "queryinfo"){
        Object.keys(queryValues["queryinfo"]).map((itemKey) => {
          const item = queryValues["queryinfo"] ? queryValues["queryinfo"][itemKey] : "";
          if (item.type=="string" && item.value == "") isValidated = false;
          if (item.type=="array" && item.value.length == 0) isValidated = false;
        })
      } else if (queryValues[template]["checked"].value){
        countTemplate++;
        Object.keys(queryValues[template]).map((itemKey) => {
          const item = queryValues[template][itemKey];          
          if (item.type=="string" && item.value == "") isValidated = false;
          if (item.type=="array" && item.value.length == 0) isValidated = false;
        })
      } 
    })      
    return countTemplate > 0 ? isValidated : false;
  }

  const handleChange = (template, field, event, newValue=[]) => {            
    let value = field == "checked" ? !queryValues[template][field].value : event.target.value;        
    if (template == "country_template" && field == "country"){
      value = newValue;
    }    
    const isValidate = CheckValidate(template, field, value);    
    setDisabled(!isValidate);        
    handleValueChange(template, field, value)
  }
  console.log('queryValues', queryValues);
  return (
    <Modal
      open={open}
      onClose={handleCloseQueryModdal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
        background: "rgba(0, 0, 0)",
        opacity:"0.85"
        }
      }}
    >
      <Container>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Query Template
        </Typography>
        <Box>        
          <Stack                      
            direction="column"
            justifyContent="space-between"
            alignItems="left"
            spacing={2}
            sx={{ mt: 2}}>            
            <TextField id="standard-basic" label="Name" variant="standard" 
            value={queryValues["queryinfo"]["name"].value}  
            onInput={(e) => { handleChange("queryinfo", "name", e) }}
            />            
          </Stack>
          <Stack                      
            direction="column"
            justifyContent="space-between"
            alignItems="left"
            spacing={2}
            sx={{ mt: 2 }}>            
            <TextField id="standard-basic" label="Title" variant="standard" 
            value={queryValues["queryinfo"]["title"].value}        
            onInput={(e) => { handleChange("queryinfo", "title", e) }}
            />    
          </Stack>
          <Stack                      
            direction="column"
            justifyContent="space-between"
            alignItems="left"
            spacing={2}
            sx={{ mt: 2 }}>            
            <TextField id="standard-basic" label="CompanyName" variant="standard" 
            value={queryValues["queryinfo"]["companyName"]?.value}        
            onInput={(e) => { handleChange("queryinfo", "companyName", e) }}
            />    
          </Stack>
          {
            Object.keys(queryValues).map((template, index) => {                      
              if (template == "queryinfo") return;                           
              return (
                <Box key={index}>                  
                  <Stack                      
                      direction={{ xs: 'column', sm: 'row' }}                      
                      alignItems="left"
                      spacing={2}
                      sx={{ mt: 2 }}>                
                  <CheckBox className='labelText' key={`template-${index}`} margin={template == "age_template"} label={queryValues[template]["checked"].label} checked={queryValues[template]["checked"].value} onChange={ (e) => handleChange(template, "checked", e)} />
                  {Object.keys(queryValues[template]).map((key, index) => {                    
                    if (key == "checked") return;
                    const item = queryValues[template][key]   
                    if (queryValues[template]["checked"].value ){
                      if (item.element === "input"){
                        return <TextField
                        id="outlined-basic" label="" variant="outlined"                         
                        value={item.value}
                        onInput={(e) => { handleChange(template, key, e) }}
                        />
                      } else if (item.element === "select"){
                        return <Selector                        
                          selectedOption={item.value}
                          handleOptionChange={(e) => { handleChange(template, key, e) }}
                          options={item.options}
                        />
                      } else if (item.element === "auto_complete"){
                        return <AutoComplete
                          value={item.value}
                          defaultValue={item.value}
                          options={item.options}
                          handleOptionChange={(event, newValue) => { handleChange(template, key, event, newValue)}}
                        />
                      }
                    }     
                    return;                                
                  })}
                  </Stack>
                </Box>
              )
            })
          }
        </Box>
        <Stack
          direction="row"          
          justifyContent="right"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <OutlineButton
            variant="outlined"
            onClick={onCancel}
            name={'Cancel'}
          />
          <BorderButton
            onClick={onSave}
            name={'Save'}
            disabled={isDisabled}
          />
        </Stack>
      </Container>
    </Modal>
  )
}

export default EditModal