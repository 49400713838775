import styled from "styled-components";

export const Button = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    border-radius: 50%;
    cursor: pointer;
    > span{
        position: absolute;
        left: 12px;
        top: 8px;
        color: green;        
        font-size: 24px;    
        width: 24px;
        height: 24px;
    }    
    background-color: ${'var(--primary)'};

    &.active ,
    &:hover{
        border-radius: 16px;
        background-color: ${({isHome})=> isHome ? 'var(--rocketseat)':'var(--discord)'};
    }
`;