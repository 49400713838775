import React from 'react'
import { Container, ButtonContainer, QrCodeIcon, Icons, LogoutIcon, SettingsIcon } from './BottomButtonLineStyles';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Slices/Auth.Slice";
import { useNavigate } from "react-router-dom";

const BottomButtonLine = ({menu}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();    
  const { user } = useSelector((state) => state.auth);

  const LogOut = () => {
    dispatch(logout()).unwrap()
    .then(() => {
        console.log('finished');
        navigate("/");
        window.location.reload();
    })
    .catch(() => {
      console.log('logout err');
    });;
  }  

  const handleClick = (menuName) => {        
      if (menuName == "query"){
          navigate(`/${user.id}/queries`);          
      } else {
          navigate(`/${user.id}/config`);          
      }
  }

  return (
    <Container>      
      <ButtonContainer>
          <Icons> 
                <QrCodeIcon actived={menu === "query" ? true : false} onClick={() => handleClick("query")}/>
          </Icons>
      </ButtonContainer>
      <ButtonContainer>
          <Icons> 
                <SettingsIcon actived={menu !== "query" ? true : false} onClick={() => handleClick("botconfig")}/>
          </Icons>
      </ButtonContainer>      
      <ButtonContainer>            
          <Icons>
                <LogoutIcon onClick={() => LogOut()} />
          </Icons>
      </ButtonContainer>
    </Container>
  )
}

export default BottomButtonLine