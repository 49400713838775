import styled from "styled-components";
import {ExpandMore} from "styled-icons/material";

export const Container = styled.div`
    display: block;
    align-items: center;
    justify-content: space-between;    
    padding: 10px 16px 0 16px;
    background-color: var(--secondary);
    box-shadow: rgba(0,0,0,0.2) 0 1px 0 0;
    z-index: 2;
`;

export const Title = styled.p`
    font-size: 16px;
    color:var(--white);
`;

export const SubText = styled.p`
    font-size: 12px;
    color:var(--white);
`;

export const ExpandIcon = styled(ExpandMore)`
    width: 28px;
    height:28px;
    color: var(--white);
    cursor:pointer;
`;