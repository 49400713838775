import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setGuilds } from "./Discord.Slice";
import AuthService from "../../Services/Auth.Service";
import DiscordService from "../../Services/Discord.Service";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
  "auth/login",
  async ({ discord_access_token }, thunkAPI) => {
    try {
      const data = await AuthService.login(discord_access_token);      
      const guilds = await DiscordService.getGuilds(data.id);
      thunkAPI.dispatch(setGuilds({guilds: guilds}));
      return { user: data };  
    } catch (error) {
      console.log('error', error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: true, user: {name: "itdream007", id: "322739237554356234", avatar: "2a50f91c03c98366a7f87d7868bcaee0"} };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;