import React from 'react';
import { Container } from './MenuButtonStyles';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
const MenuButton = ({menuName,selectedMenu}) => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);  
    const { selected } = useSelector((state) => state.discord);

    const handleClick = () => {        
        if (menuName == "query"){
            navigate(`/${user.id}/queries`);
            //window.location = `/${user.id}/${selected}/queries`;
        } else {
            navigate(`/${user.id}/config`);
            //window.location = `/${user.id}/${selected}/config`;
        }
    }

  return (
      <Container className={selectedMenu ? 'active':''} onClick={() => handleClick()}>
          <div>              
              <span>{menuName}</span>
          </div>
      </Container>
  )
}

export default MenuButton