import React from 'react'
import MenuButton from '../MenuButton/MenuButton'
import {Container, Logo} from './MenuListStyles'

const MenuList = ({menu}) => {
  return (
    <Container>
        <Logo>
          <img src="/sharering-logo.png" />
        </Logo>
        <MenuButton menuName='query' selectedMenu={menu=="query" ? true: false} />
        <MenuButton menuName='bot config' selectedMenu={menu=="query" ? false: true}/>        
    </Container>
  )
}

export default MenuList