
import React, { useState,useEffect } from "react";
import Layout from '../../Components/Layout/Layout';
import { Container } from "./ViewQueryStyles"
import EditModal from '../../Components/Queries/EditModal/Edit';
import DeleteModal from '../../Components/Queries/DeleteModal/Delete';
import Button from '../../Components/Common/Buttons/Button';
import DeleteButton from '../../Components/Common/Buttons/DeleteButton';
import Loader from 'react-loader';
import DiscordService from '../../Services/Discord.Service';
import { useDispatch, useSelector } from "react-redux";
import {countries} from "./Country";
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Stack,
    Snackbar,
    Alert
  } from '@mui/material';



const QUERY_TEMPLATES = {age_template: {label: "Age Query", value:"age_template"}, country_template: {label: "Country Query", value: "country_template"}};

const countryArr=Object.entries(countries).map(([key,value])=>{
    return {
        label:value,
        value:key
    }
})
const QUERY_VALUES = {
    "queryinfo": {
        name: {element: "input", type: "string", value: "", label: "Query Name"},
        title: {element: "input", type: "string", value: "", label: "Query Title"},
        companyName: {element: "input", type: "string", value: "", label: "Server Name"},
    },
    "age_template": {
        checked: {element: "check", type: "bool", value: false, label: "Verify Age    ", required_field: "age"},
        ageOps: {element: "select", type: "string", options: [{label:"Over", value: "over"}, {label: "Under", value: "under"}], value: "over"},
        age: {element: "input", type: "number", value: 18},                
    },
    "country_template": {
        checked: {element: "check", type: "bool", value: false, label: "Verify Country", required_field: "country"},        
        option: {element: "select", type: "string", options: [{label:"IS", value: "IS"}, {label: "IS NOT", value: "IS NOT"}], value: "IS"},
        country: {element: "auto_complete",  type: "array", options: countryArr, value: []}
    },    
    "device_unique_template": {
        checked: {element: "check", type: "bool", value: false, label: "Verify Device Uniqueness", required_field: "device_identifier"}
    }
}

const ViewQuery = (props) => {        
    const { user } = useSelector((state) => state.auth);  
    const { selected, guilds } = useSelector((state) => state.discord);  

    const [reload, setReload] = useState(false);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    
    const [queries, setQueries] = useState([])
    const [selectedQuery, setSelectedQuery] = useState(null)
    const [selectedTemplate, setTemplate] = useState(Object.keys(QUERY_TEMPLATES)[0]);
    const [values, setValues] = useState(QUERY_VALUES);
    const [loading, setLoading]= useState(false)
    const [saved, setSaved]= useState(false)
    const [message, setMessage]= useState("Saved Changes");
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
      const { vertical, horizontal, open } = state;

    console.log('QUERY_VALUES', QUERY_VALUES);
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])


    useEffect(() => {        
        DiscordService.getQueries({userId: props.user.id}).then((data) => {
            if (data){
                setQueries(data);   
            }            
        })
    }, [reload]);
    
    useEffect(() => {
        const guild = guilds.filter((item)=>{            
            return item.id == selected
        })
        if (guild.length > 0){
            QUERY_VALUES.queryinfo["companyName"].value = guild[0].name;
            setValues(QUERY_VALUES);
        }

    }, [selected, guilds])
    const onShowEditModal = (isEdit) => {        
        if (!isEdit){
            setValues(QUERY_VALUES);
            setSelectedQuery(null);
        }
        setEditModalOpen(true)
    };

    const onShowDeleteModal = (row) => {
        setSelectedQuery(row);
        setTimeout(() => setDeleteModalOpen(true), 100);        
    };

    const handleCloseQueryModdal = () => setEditModalOpen(false);
    const handleCloseDeleteModdal = () => setDeleteModalOpen(false);

    const handleValueChange = (template, field, fieldValue) => {        
        const newValues = JSON.parse(JSON.stringify(values))        
        newValues[template][field].value = fieldValue;
        setValues(newValues);
    }

    const handleSelectTemplate = (template) => {
        setTemplate(template);
    }

    const onEdit = (query) => {            
        if (query){
            const newValues = JSON.parse(query.queryInfo);            
            setValues(newValues);
        }        
        setSelectedQuery(query);
        setTimeout(() => onShowEditModal(true), 100);
    };

    const onDelete = () => {        
        DiscordService.deleteQuery({queryId: selectedQuery.queryId}).then((data) => {            
            handleCloseDeleteModdal()            
            setSelectedQuery(null);          
            setReload(!reload);  
            setMessage("Query Deleted");
            setSaved(true);
        })        
    };    

    const MakeQueryData = () => {
        const data = {}
        data.name = values["queryinfo"]["name"].value;
        data.title = values["queryinfo"]["title"].value;
        data.companyName = values["queryinfo"]["companyName"].value;
        data.color = "#00FF00"; 
        data.queryId = selectedQuery ? selectedQuery.queryId : null;
        data.fields_info = [];
        data.apiEndpointIds = [];
        data.strQuery = "";
        Object.keys(values).map((template) => {
            if (template == "queryinfo") return;
            if (values[template]["checked"].value){
                data.fields_info.push({name: values[template]["checked"].required_field, is_required: true, is_one_time: false, is_custom: false});
                if (data.strQuery == "") {
                    data.strQuery = `return "${values[template]["checked"].required_field}:" + VALUE("${values[template]["checked"].required_field}")`;
                } else {
                    data.strQuery = data.strQuery + `+"||"+` + `"${values[template]["checked"].required_field}:" + VALUE("${values[template]["checked"].required_field}")`
                }                
            }
        })            
        data.strQuery = data.strQuery + ';'
        data.queryInfo = JSON.stringify(values);        
        return data;        
    }

    const onSave = () => {        
        setLoading(true);
        handleCloseQueryModdal()        
        const queryParmas = MakeQueryData();
        queryParmas.userId = user.id;
        DiscordService.postQuery(queryParmas).then((data) => {            
            setSelectedQuery(null);            
            setLoading(false);
            setValues(QUERY_VALUES);
            setReload(!reload);  
            setMessage(selectedQuery ? "Saved Changes" : "New Query Added");
            setSaved(true);
        });        
    };

    const onCancel = () => {    
        handleCloseQueryModdal()    
        handleCloseDeleteModdal()
    };

    const handleCloseNotification = () => {
        setSaved(false);
    };

return (
    <React.Fragment>
        <Layout>
            <Container>
            {loading && <Loader loaded={!loading} />}
                <Stack
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                    spacing={2}
                    sx={{marginRight:2, mt:2}}
                >
                    <Button
                        onClick={() => {onShowEditModal(null)}}
                        name={'Add'}    
                    />
                </Stack>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            {screenSize.width > 767 && <TableCell className="tableCell">Id</TableCell>}
                            <TableCell className="tableCell">Query</TableCell>
                            {screenSize.width > 767 && <TableCell align="right" className="tableCell">CreatedAt</TableCell>}
                            <TableCell align="right" className="tableCell">Actions</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {queries.map((row) => (
                            <TableRow key={row.name}  >
                                {screenSize.width > 767 && <TableCell className="tableCell"
                                >{row?.queryId }</TableCell > }                 
                                <TableCell component="th" scope="row" className="tableCell"> {row?.name}</TableCell>
                                {screenSize.width > 767 && <TableCell align="right" component="th" scope="row" className="tableCell"> {new Date(row?.createdAt).toLocaleString()}</TableCell>}
                                <TableCell align="right" component="th" scope="row" className="tableCell">
                                <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button
                                        onClick={() => {onEdit(row)}}
                                        name={'Edit'}  
                                    />
                                    <DeleteButton
                                        onClick={() => {onShowDeleteModal(row)}}
                                        name={'Delete'}  
                                    />
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {editModalOpen&&<EditModal
                    queryTemplates={QUERY_TEMPLATES}
                    selectedTemplate={selectedTemplate}
                    handleSelectTemplate={handleSelectTemplate}
                    queryValues={values}                                    
                    open={editModalOpen}     
                    QUERY_VALUES={QUERY_VALUES}               
                    handleValueChange={handleValueChange}                
                    handleCloseQueryModdal={handleCloseQueryModdal}
                    onCancel={onCancel}
                    onSave={onSave}
                />}
                <DeleteModal
                    open={deleteModalOpen}
                    onDelete={onDelete}
                    handleCloseDeleteModdal={handleCloseDeleteModdal}
                    onCancel={onCancel}
                />
            </Container>
            <Snackbar 
                        open={saved} 
                        autoHideDuration={50000} 
                        anchorOrigin={{ vertical, horizontal}}
                        onClose={handleCloseNotification}                                                
                        sx={{width: "50%"}}
                        >
                        <Alert onClose={handleCloseNotification} severity="success" sx={{ width: '100%', background: "#48C4A1", color: "white" }}>
                            {message}
                        </Alert>
            </Snackbar>
        </Layout>    
    </React.Fragment>
)
}

export default ViewQuery