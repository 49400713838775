import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
const ViewMain = () => {
    const { user } = useSelector((state) => state.auth);      
    let navigate = useNavigate();   
    useEffect(() => {
        console.log('user', user);
        setTimeout(() => {
            if (user?.id){
                navigate(`/${user.id}/queries`);
            } else {
                window.location = `/login`;
            }                
        }, 1000);
    })
    return (
        <React.Fragment>
        </React.Fragment>
    )
}

export default ViewMain