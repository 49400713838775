import React from 'react';
import { Container, Profile, Avatar, UserData, Icons, LogoutIcon } from './BottomUserInfoStyles'
import { useDispatch, useSelector } from "react-redux";
const BottomUserInfo = () => {    
    const { user } = useSelector((state) => state.auth);
    return (
        <Container>
            <Profile>
                <Avatar>
                    <img src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`} />
                </Avatar>
            </Profile>
        </Container>
    )
};

export default BottomUserInfo;