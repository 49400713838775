import React from 'react'
import { Container, Separator } from './ServerListStyles';
import ServerButton from '../ServerButton/ServerButton';
import AddButton from '../ServerButton/AddButton';
import LogoButton from '../ServerButton/LogoButton';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedGuild, setRoles } from "../../Redux/Slices/Discord.Slice";
import { DISCORD_AUTH_REDIRECT, DISCORD_CLIENT_ID } from "../../Services/Config"
import DiscordService from "../../Services/Discord.Service";
const ServerList = () => {
  const { guilds, selected } = useSelector((state) => state.discord);  
  const { user } = useSelector((state) => state.auth);  
  const dispatch = useDispatch();

  const generateAcronym = (str) => {
    // Split the string into an array of words
    const words = str.split(' ');
  
    // Map each word to its first character and convert it to uppercase
    const acronym = words.map(word => word[0].toUpperCase()).join('');
  
    return acronym;
  }

  const onClickHandler = (guildId) => {
    dispatch(setSelectedGuild({selected: guildId}));
    DiscordService.getRoles(guildId).then((data) => {       
        const roles = data.filter((item) => {
          if (item.name != "sharering-vql-verify")
            return item;
        })                                  
        dispatch(setRoles({roles: data}));                    
    })    
  } 

  const onAddClickHander = () => {    
    const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&permissions=268454932&redirect_uri=${encodeURIComponent(DISCORD_AUTH_REDIRECT)}&response_type=token&scope=bot+applications.commands+identify+guilds`;

    // Redirect the user to the authorization URL
    window.location.href = authUrl;
  }

  return (
    <Container>
        <LogoButton/>
        <Separator />
        {
          guilds.map((item, index) => {
            let isSelected = false;
            if (selected == null && index == 0) {
              isSelected = true;
            } else if (item.id == selected ) {
              isSelected = true;
            }

            let icon = ""
            if (item.icon){
              icon = `https://cdn.discordapp.com/icons/${item.id}/${item.icon}.png?size=256`
            }

            return (
              <ServerButton key={item.id} hasNotifications name={generateAcronym(item.name)} selected={isSelected} icon={icon} guildId={item.id} onClickHandler={onClickHandler}/>
            )
          })
        }     
        <AddButton onClickHandler={onAddClickHander}/>
    </Container>
  )
}

export default ServerList