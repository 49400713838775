import styled  from 'styled-components';

export const Container = styled.div`    
    display:flex;
    flex-direction: column;    
    background-color: var(--primary);
    width: 100%;
    height: 100%;
`;

export const Title = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary);    
    margin: 100px auto 20px auto;
    > img {        
            width: 32px;
            height: 32px;      
            margin-right: 10px;  
    }
    > span {        
        font-size: 30px;
        color: white;
    }
`;

export const WelcomeText = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary);    
    margin: 20px auto;
    > span {        
        font-size: 30px;
        color: white;
    }
`;

export const ImageSection = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary);    
    margin: 20px auto;
    > img {        
        width: 300px;
    }
`;

export const ButtonSection = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary);    
    margin: 20px auto;
    > button {        
        background-color: #f6c349;
        border: 2px solid #f6c349;
        border-radius: 8px;
        color: #111827;
        padding: 5px 10px;
        align-item: center;
        font-size: 14px;
        font-height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    > button img {        
        width: 24px;
        margin-right: 5px;
    }
`;