import axios from "axios";

import { DISCORD_BACKEND_API_URL, DISCORD_API_URL } from "./Config"

const getGuilds = async (ownerId) => {
    try {      
      console.log('getGuilds ownerId', ownerId); 

      const response = await axios.get(`${DISCORD_BACKEND_API_URL}/guilds?ownerId=${ownerId}`, {
        // headers: {
        //   Authorization: `Bearer ${discord_access_token}`,
        // },
      });
      console.log('guilds response', response);
      const guilds = response.data;
      console.log('guilds', guilds);
      return guilds;
    } catch (error) {
      console.error('Error fetching guilds:', error.message);
    }
}

const getRoles = async (guildId) => {
  try {      
    console.log('roles guildId', guildId); 

    const response = await axios.get(`${DISCORD_BACKEND_API_URL}/roles?guildId=${guildId}`, {
      // headers: {
      //   Authorization: `Bearer ${discord_access_token}`,
      // },
    });
    console.log('roles response', response);
    const roles = response.data;
    console.log('roles', roles);
    return roles;
  } catch (error) {
    console.error('Error fetching roles:', error.message);
  }
}

const getQueries = async (params) => {
  try {      
    console.log('params', params); 
    const response = await axios.get(`${DISCORD_BACKEND_API_URL}/queries?userId=${params.userId}`, {
      // headers: {
      //   Authorization: `Bearer ${discord_access_token}`,
      // },
    });    
    console.log('getQueries response', response);
    const data = response.data;
    console.log('getQueries', data);
    return data.items;
  } catch (error) {
    console.error('Error fetching queries:', error.message);
  }
}

const postQuery = async (params) => {
  try {      
    console.log('params', params); 
    params.createdBy = "discord-plugin-dashboard"
    const response = await axios.post(`${DISCORD_BACKEND_API_URL}/query`, params);    
    console.log('createQuery response', response);
    const roles = response.data;
    console.log('createQuery', roles);
    return roles;
  } catch (error) {
    console.error('Error create Query', error.message);
  }
}

const deleteQuery = async (params) => {
  try {      
    console.log('params', params); 
    params.createdBy = "discord-plugin-dashboard"
    const response = await axios.delete(`${DISCORD_BACKEND_API_URL}/query?queryId=${params.queryId}`);    
    console.log('deleteQuery response', response);
    const roles = response.data;
    console.log('deleteQuery', roles);
    return roles;
  } catch (error) {
    console.error('Error deleteQuery', error.message);
  }
}

const getBotConfig = async (params) => {
  try {      
    console.log('params', params); 
    const response = await axios.get(`${DISCORD_BACKEND_API_URL}/botconfig?userId=${params.userId}&guildId=${params.guildId}`, {
      // headers: {
      //   Authorization: `Bearer ${discord_access_token}`,
      // },
    });    
    console.log('getBotConfig response', response);
    const roles = response.data;
    console.log('getBotConfig', roles);
    return roles;
  } catch (error) {
    console.error('Error fetching getBotConfig:', error.message);
  }
}

const setBotConfig = async (params) => {
  try {      
    console.log('params', params);     
    const response = await axios.post(`${DISCORD_BACKEND_API_URL}/botconfig`, params);    
    console.log('setBotConfig response', response);
    const roles = response.data;
    console.log('setBotConfig', roles);
    return roles;
  } catch (error) {
    console.error('Error setBotConfig', error.message);
  }
}

const DiscordService = {  
  getGuilds,
  getRoles,
  getQueries,
  postQuery,
  deleteQuery,
  getBotConfig,
  setBotConfig
}

export default DiscordService;