import React from 'react'
import { Container, Title, SubText} from './ServerNameStyles'
import { useDispatch, useSelector } from "react-redux";

const ServerName = () => {  
  const { guilds, selected } = useSelector((state) => state.discord);  

  const guildsSelected = guilds.filter((item) => {return item.id == selected })
  let guild = guilds.length > 0 ? guilds[0] : null;
  guild = guildsSelected.length > 0 ? guildsSelected[0] : guild;

  return (
    <Container>
        <Title>{guild ? guild.name : ""}</Title>               
        <SubText>Logged in with Discord</SubText>
    </Container>
  )
}

export default ServerName