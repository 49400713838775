import React from 'react'
import { Button } from './AddButtonStyles';

const AddButton = ({onClickHandler}) => {
  return (
    <Button onClick={onClickHandler}>
        <span>+</span>
    </Button>
  )
}

export default AddButton