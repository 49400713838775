import { createSlice } from "@reduxjs/toolkit";

const initialState = { guilds: [], selected: null, roles: [] };

const discordSlice = createSlice({
  name: "discord",
  initialState,
  reducers: {
    setSelectedGuild: (state, action) => {
        return {
            ...state,
            ...action.payload, // Merge the partial state into the current state
        };      
    },  
    setRoles: (state, action) => {
        return {
            ...state,
            ...action.payload, // Merge the partial state into the current state
        };      
    },  
    setGuilds: (state, action) => {
        return {
            ...state,
            ...action.payload, // Merge the partial state into the current state
        };      
    },
    clearGuilds: () => {
      return { guilds: [], selected: null };
    },
  },
});

const { reducer, actions } = discordSlice;

export const { setGuilds, clearGuilds, setRoles, setSelectedGuild } = actions
export default reducer;