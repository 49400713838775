import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: var(--white);
    border: 1px solid #000;
    color:var(--black);
    box-shadow: 24px;
    padding: 32px;

    @media only screen and (max-width: 768px) {        
        width: 350px;
    }
`;
