import React from 'react'
import { Button } from './LogoButtonStyles';

const LogoButton = () => {
  return (
    <Button>
      <img src="/sharering_icon_white.png" />
    </Button>
  )
}

export default LogoButton