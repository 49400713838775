import React from 'react';
import { Container, Profile, Avatar, UserData, Icons, LogoutIcon } from './UserInfoStyles'
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Slices/Auth.Slice";
import { useNavigate } from "react-router-dom";
const UserInfo = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();    
    const { user } = useSelector((state) => state.auth);
    // console.log('user', user);
    const LogOut = () => {
        dispatch(logout()).unwrap()
        .then(() => {
            console.log('finished');
            navigate("/");
            window.location.reload();
        })
        .catch(() => {
          console.log('logout err');
        });;
    }

    return (
        <Container>
            <Profile>
                <Avatar>
                    <img src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`} />
                </Avatar>
                <UserData>
                    <strong>{user?.username}</strong>
                    {/* <span>#5801</span> */}
                </UserData>
            </Profile>
            <Icons>
                <LogoutIcon onClick={() => LogOut()} />
            </Icons>
        </Container>
    )
};

export default UserInfo;