import React, { useEffect, useState } from "react";
import { Container } from './AutoCompleteStyles';
import {
    Autocomplete,
    TextField,
  } from '@mui/material';
const AutoComplete = ({handleOptionChange, width, value, options}) => {    
    return (        
        <Autocomplete
            multiple
            disablePortal
            options={options}
            limitTags={1}
            value={value}
            getOptionLabel={(option) => option.label}
            onChange={(e, newValues) => {                
                return handleOptionChange(e, newValues);
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                />
            )}
            sx={{ 
                minWidth: "200px",
                borderRadius:0
            }}
            ListboxProps= {{
                sx: {
                    padding:0,                  
                }
            }}
        />
    )}

export default AutoComplete