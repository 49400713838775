import React, { useState } from "react";
import { Container } from './SelectorStyles';
import {
    Select,
    MenuItem,
    styled
  } from '@mui/material';

const SelectWrapper = styled(Select)(()=>({
    '& .MuiMenu-paper': {
        borderRadius:'0 !important'
    }
}))  
const Selector = ({selectedOption,handleOptionChange,width, options,mode}) => {

    return (
        <SelectWrapper
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ 
            color: 'var(--black)' ,            
            borderRadius:"0px"
            }}
            displayEmpty
            // MenuProps={{ "& .MuiMenu-paper": {
            //     // Use any CSS properties you want to change the style
            //     backgroundColor: "gray",
            //     borderRadius: "10px",
            //     boxShadow: "5px 5px 10px black"
            //     } }}
            inputProps={{ 'aria-label': 'Without label',MenuProps: {
                borderRadius:0,                
                MenuListProps: {
                    sx: {
                        backgroundColor: 'var(--white)',
                        padding:0,
                        "& .Mui-selected": {
                            backgroundColor: "var(--white)",
                            color:'var(--black)'
                        },
                        "& .MuiMenuItem-root:hover": {
                            backgroundColor: "var(--btn-primary)"
                        }
                    }
                }
            } }}
            >
        {!!options?.length &&
            options.map(({ label, value }) => (
                <MenuItem sx={{ color: 'var(--label-text)', borderRadius: 0 }} key={value} value={value}>
                {label}
                </MenuItem>
            ))}
    </SelectWrapper>
    )
}

export default Selector