import React from 'react';
import { Container } from './DeleteButtonStyles';

const DeleteButton = ({name,selected,disabled, onClick}) => {
    const selectedClass=disabled ? 'disabled':''
    const disableClass=selected ? 'active':''
    const classname=selectedClass+" "+disableClass
  return (
    <Container onClick={onClick} className={classname}>
      <div>              
        <span>{name}</span>
      </div>
    </Container>
  )
}

export default DeleteButton