import React, { useState, useEffect } from 'react';
import Layout from '../../Components/Layout/Layout';
import { Container } from "./ViewBotConfigStyles"
import { useDispatch, useSelector } from "react-redux";
import SelectorConfig from '../../Components/Common/Selector/SelectorConfig';
import BorderButton from '../../Components/Common/Buttons/BorderButton';
import Loader from 'react-loader';
import DiscordService from '../../Services/Discord.Service';
import {  setRoles } from "../../Redux/Slices/Discord.Slice";
import { 
    Stack,
    Typography,
    Snackbar,
    Alert,
    Box
} from '@mui/material';

const ViewBotConfig = () => {    
    const { user } = useSelector((state) => state.auth);
    const { roles, selected } = useSelector((state) => state.discord);

    const [query, setQuery]= useState("")
    const [queries, setQueries]= useState([])
    const [disableSave, setDisableSave]= useState(true)
    const [role, setRole]= useState("")
    const [guildRoles, setSelectRoles]= useState([])    
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading]= useState(false)
    const [saved, setSaved]= useState(false)
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            console.log('ViewBotConfig roles', roles);

            if (roles.length <= 0){
                DiscordService.getRoles(selected).then((data) => {       
                    const rolesNew = data.filter((item) => {
                      if (item.name != "sharering-vql-verify")
                        return item;
                    })      
                    const rolesTmp = rolesNew.map((item) => {
                        return {label: item.name, value: item.id}
                    })            
                    console.log("rolesTmp", rolesTmp);
                    setSelectRoles(rolesTmp);                       
                    dispatch(setRoles({roles: rolesNew}));                    
                })     
            } else {
                const rolesTmp = roles.map((item) => {
                    return {label: item.name, value: item.id}
                })            
                setSelectRoles(rolesTmp);
            }

            DiscordService.getQueries({userId: user.id}).then((res) => {                
                if (res){
                    let tmp = res.map((item) => {
                        return {label: item.name, value: item.queryId}
                    })                                            
                    setQueries(tmp);                           
                    DiscordService.getBotConfig({userId: user.id, guildId: selected}).then((data) => {
                        if (data){
                            console.log('getBotConfig', data);
                            setQuery(data.queryId);
                            if (data.roles){
                                const tmpRole = JSON.parse(data.roles)[0];
                                setRole(tmpRole);
                            }                                                                                                                
                        }                                    
                    })

                }                            
            })            
        }
    }, [selected])

    const handleQueryChange = (event) => {
        console.log('handleQueryChange', event.target.value);
        setQuery(event.target.value);
        if (role)
            onEnableSave()
    };
    const handleRoleChange = (event) => {
        console.log('handleRoleChange', event.target.value);
        setRole(event.target.value);
        if (query)
            onEnableSave()        
    };
    
    const onEnableSave = () => {
        setDisableSave(false)
    };

    const onSave = () => {
        setLoading(true);
        setDisableSave(true)
        console.log('query', query);
        console.log('roles', role);
        DiscordService.setBotConfig({userId: user.id, guildId: selected, roles: [role], queryId: query}).then((data) => {
            if (data.status){
                console.log('setBotConfig', data);
            }             
            
            setLoading(false);
            setSaved(true)
        })
    };

    const handleCloseNotification = () => {
        setSaved(false);
    };

    
    return (
        <React.Fragment>
            <Layout menu="bot config">
                <Container>
                    <div className={loading ? 'parentDisable' : ''} width="100%">
                        <Box className='overlay-box'>
                            {loading && <Loader loaded={!loading} />}
                        </Box>
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                        spacing={2}
                        sx={{color:'var(--white)' }}
                    >
                        <Typography sx={{width:'15%'}}>
                            Query
                        </Typography>
                        <SelectorConfig
                            selectedOption={query}
                            handleOptionChange={handleQueryChange}
                            options={queries}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                        spacing={2}
                        sx={{mt:2, color:'var(--white)' }}
                    >
                        <Typography sx={{width:'15%'}}>
                            Role
                        </Typography>
                        <SelectorConfig
                            selectedOption={role}
                            handleOptionChange={handleRoleChange}
                            options={guildRoles}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt:2}}
                    >
                        <BorderButton
                            disabled={disableSave}
                            onClick={onSave}
                            name={'Save'}    
                        />
                    </Stack>
                    <Snackbar 
                        open={saved} 
                        autoHideDuration={50000} 
                        onClose={handleCloseNotification}                        
                        anchorOrigin={{ vertical, horizontal }}
                        sx={{width: "50%"}}
                        >
                        <Alert onClose={handleCloseNotification} severity="success" sx={{ width: '100%', background: "#48C4A1", color: "white" }}>
                            Saved Changes
                        </Alert>
                    </Snackbar>
                </Container>
            </Layout>
        </React.Fragment>
    )
}

export default ViewBotConfig