import React, {useEffect, useState} from 'react';
import GlobalStyles from './Styles/GlobalStyles';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";

import ViewBotConfig from './Views/Bot/ViewBotConfig'
import ViewQuery from './Views/Bot/ViewQuery';
import ViewMain from './Views/ViewMain'
import ViewLogin from './Views/Auth/ViewLogin';
import ErrorPage from './ErrorPage'
import DiscordAuth from './Views/Auth/DiscordAuth';
import { useDispatch, useSelector } from "react-redux";
import { setGuilds, setRoles, setSelectedGuild } from "./Redux/Slices/Discord.Slice";
import DiscordService from "./Services/Discord.Service";
import { login, logout } from "./Redux/Slices/Auth.Slice";

const AuthRoute = ({isAuth}) => {      
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
}

function App() {
  const { user } = useSelector((state) => state.auth);  
  const { selected } = useSelector((state) => state.discord);  
  const dispatch = useDispatch();

  useEffect(() => {    
    const accessToken = localStorage.getItem("discord_access_token");        
    if (accessToken) {                
        if (user){
          DiscordService.getGuilds(user.id).then((data) => {            
            
            dispatch(setGuilds({guilds: data}));

            let guildId = selected;
            if (!selected){
                guildId = data.length > 0 ? data[0].id : null
                console.log('--guildId', guildId);
                dispatch(setSelectedGuild({selected: guildId}));
            }
            console.log('------------ selected', selected, guildId);
            DiscordService.getRoles(guildId).then((data) => {       
                const roles = data.filter((item) => {
                  if (item.name != "sharering-vql-verify")
                    return item;
                })                             
                dispatch(setRoles({roles: roles}));                    
            })                                
          })   
        } else {
          dispatch(login({discord_access_token: accessToken})).unwrap()
          .then(() => {
              console.log('fetch data');                 
          })
          .catch((err) => {
            //setLoading(false);
            console.log('abc');
          });
        }         
    }
  }, [user])


  return (
    <>
        <BrowserRouter>
          <Routes>
            <Route
                path="/"
                element={<AuthRoute isAuth={localStorage.getItem("discord_access_token") ? true : false}/>}
            >   
                <Route path="/" element={ <ViewMain/>} />                                    
                <Route path="/:userId/config" element={<ViewBotConfig user={user}/>} />            
                <Route path="/:userId/queries" element={<ViewQuery user={user}/>} />     
            </Route>
            <Route path="/login" element={<ViewLogin isAuth={localStorage.getItem("discord_access_token") ? true : false} />} />            
            <Route path="/api/discord/auth" element={<DiscordAuth />} />            
            <Route path="*" element={<Navigate to={`/`} />} />
          </Routes>
        </BrowserRouter>        
        <GlobalStyles/>
    </>
    );
}

export default App;
