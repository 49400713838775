
import React, {useEffect, useState} from 'react';
import {Container} from './DiscordAuthStyles';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/Slices/Auth.Slice";
import { Navigate, useNavigate } from "react-router-dom";
const DiscordAuth = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();    
    // const { isLoggedIn } = useSelector((state) => state.auth);
   const [loading, setLoading] = useState(false)
    useEffect(() => {
        const hashParams = new URLSearchParams(window.location.hash.substr(1));
        const error = hashParams.get('error');
        if (error){
            window.location = "/"
        } else {
            const accessToken = hashParams.get('access_token');
            console.log('DiscordAuth accessToken', accessToken);
            dispatch(login({discord_access_token: accessToken})).unwrap()
            .then(() => {
                console.log('finished');
                navigate("/");          
            })
            .catch(() => {
              setLoading(false);
            });;
        }

    }, [])

    return (
        <Container/>    
    )
}

export default DiscordAuth