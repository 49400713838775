import React from 'react'
import { Container } from './MobileBottomStyles'
import BottomButtonLine from './BottomButtonLine'
import BottomUserInfo from './BottomUserInfo'

const MobileBottom = ({menu="query"}) => {
  return (
    <Container>        
      <BottomUserInfo/>
      <BottomButtonLine menu={menu}/>      
    </Container>
  )
}

export default MobileBottom