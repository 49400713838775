import React from 'react'
import { Button } from './ServerButtonStyles';

const ServerButton = ({selected, name, icon, guildId, onClickHandler}) => {
  return (
    <Button
        // isHome={isHome}
        hasNotifications={selected}        
        className={selected ? 'active':''}
        onClick={() => {onClickHandler(guildId)}}
    >
        {icon ? <img src={`https://cdn.discordapp.com/icons/1115833110593028247/1637fc541e1ed6740d16a64cefa6b70d.png?size=256`}/> : name }
    </Button>
  )
}

export default ServerButton