import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./Slices/Auth.Slice";
import discordReducer from "./Slices/Discord.Slice";

const reducer = {
  auth: authReducer,
  discord: discordReducer
}

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});