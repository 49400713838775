import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
    *{
        margin:0;
        padding:0;
        box-sizing:border-box ;
    }

    html,body, #root{
        height: 100%;
    }
    *, button, input{
        border: 0;
        outline: none;
        font-family: 'Roboto', sans-serif;
    }
    .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiPaper-elevation {
        background-color: gray;
        border-radius: 0 !important;
    }
    .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiPaper-elevation {
        border-radius: 0 !important;
    }

    :root {
    --primary: #36393f;
    --secondary: #2f3136;
    --tertiary: rgb(32,34,37);
    --quaternary: #292b2f;
    --quinary: #393d42;
    --senary: #828386;
    
    --black: #000;
    --white: #fff;
    --gray: #8a8c90;
    --dark-gray: #555;
    --chat-input: rgb(64,68,75);
    --symbol: #74777a;
 
    --notification: #f84a4b;
    --discord: #6e86d6;
    --mention-detail: #f9a839;
    --mention-message: #413f3f;
 
    --link: #5d80d6;
    --rocketseat: #6633cc;

    --btn-primary: #3458E0;    
    --btn-hover: #4751c4;
    --outline-btn-hover: #aaa;
    --label-text: #666666;
    --label-text_: #b5bac1;
    --input-background: #1e1f22;
    --selector-options-background: #2b2d31;
    --slected-option: #404249;
    --slector-menuitem-hover: #35373c;
    --btn-disable: #ddd;
    --modal-background: #313339;
    --back-primary: #262626;
    --modal-font-hover-color: #555;
    --input-focus-color: rgba(228, 219, 233, 0.25);

  }
`;