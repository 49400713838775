import styled from 'styled-components';
import {Headset, Settings, Logout} from 'styled-icons/material'

export const Container= styled.div`
    grid-area: UI;
    display:flex;
    align-items: center;
    justify-content:space-between;
    padding:15px;    
    width: 61px;
    box-shadow: rgba(0,0,0,0.2) 0 1px 0 0;
`;


export const Profile = styled.div`
    display:flex;
    align-items:center;
`;
export const  Avatar = styled.div`
    width: 31px;
    height:31px;
    border-radius: 50%;
    background-color: var(--gray);
    > img {
        width: 31px;
        width: 31px;
    }
 `;

