import axios from "axios";

import {DISCORD_API_URL} from "./Config"

const login = (discord_access_token) => {
  console.log('discord_access_token', discord_access_token);
  return axios
    .get(DISCORD_API_URL + "/v10/users/@me", {
        headers: {
            Authorization: `Bearer ${discord_access_token}`,
        }
    })
    .then((response) => {
      console.log('discord response', response);
      if (response && response.data){
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("discord_access_token", discord_access_token);      
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("discord_access_token");      
      }     
      return response.data;    
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("discord_access_token");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {  
  login,
  logout,
  getCurrentUser,
}

export default AuthService;