import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;        
    cursor: pointer;    
    width: fit-content;
    >div{
        display:flex;
        align-items: center;
    }

    >div span{
        font-size:15px;
        color: var(--btn-primary);
    }
    & .iconsRight{
        display:none;
    }
    &.active, &:hover{        
        > div span{
            color: var(--btn-hover);
        }

        & .iconsRight{
            display: inline;
        }
    }
`;
