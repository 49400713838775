import styled from 'styled-components';
import {Add} from 'styled-icons/material';

export const Container = styled.div`
    grid-area: CL;
    display:flex;
    flex-direction: column;
    padding: 24px 9.5px 0 16px;
    background-color: var(--secondary);
`;

export const Category = styled.div`
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 16px;
     
    > span{
        text-transform: upperCase;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray);
    }
`;

export const AddCategoryIcon = styled(Add)`
    width:21px;
    height:21px;
    color: var(--symbol);
    cursor: pointer;
`;

export const Logo = styled.div`    
    display:flex;
    flex-direction: row;        
    justify-content: center;
    padding: 20px 10px;
    > img {        
        height: 30px;        
    }
`;