import styled  from 'styled-components';

export const Container = styled.div`
    grid-area: CD;
    display:flex;
    flex-direction: column;
    // justify-content: space-between;
    background-color: var(--primary);
    color:white;
    padding: 10px

    > ul {
        background-color: black !important;
    }
    .tableCell{
        border-bottom: 1px solid var(--dark-gray);
        color: #ddd;
    }
    `;

