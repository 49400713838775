import styled from "styled-components"

// SL - Server list
// SN - Sever name
// CN - Channel name
// CI - Channel Info
// UL - User List

const LayoutStyles = styled.div`
    display: grid;
    grid-template-columns: 71px 240px auto;
    grid-template-rows: 46px auto 52px;
    grid-template-areas:
        'SL SN CI'
        'SL CL CD'
        'SL UI CD'
    ;
    height: 100vh;
`; 

export default LayoutStyles;