import styled from "styled-components";
import {Headset, Settings, Logout, QrCode} from 'styled-icons/material'

export const Container = styled.div`    
    display: flex;
    background-color: ${'var(--black)'};
    width: 100%;
`;


export const ButtonContainer = styled.div`    
    display: flex;
    background-color: ${'var(--black)'};
    width: 33%;
    text-align: right;
    justify-content: right;
    padding: 0px 10px;
`;

export const Button = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 31px;
    height: 31px;
    margin-left: 10px;
    border-radius: 50%;
    color: white;        
    > img{
        width: 31px;
        height: 31px;
    }    
    background-color: ${'var(--primary)'};
`;

export const  Icons = styled.div`
    display:flex;
    align-items: center;

    > svg:not(:first-child){
        margin-left: 7px;
    }
 `;
 
export const LogoutIcon = styled(Logout)`
    width: 20px;
    height: 20px;
    color: var(--white);    
    cursor:pointer;
    transition: opacity .2s;
    opacity: 0.5;
    &:hover{
        opacity: 1;
    }
`;

export const QrCodeIcon = styled(QrCode)`
    width: 20px;
    height: 20px;
    color: var(--white);    
    cursor:pointer;
    transition: opacity .2s;
    opacity: ${({actived})=> actived? 1 : 0.5};
    &:hover{
        opacity: 1;
    }
`;

export const SettingsIcon = styled(Settings)`
    width: 20px;
    height: 20px;
    color: var(--white);    
    cursor:pointer;
    transition: opacity .2s;
    opacity: ${({actived})=> actived? 1 : 0.5};
    &:hover{
        opacity: 1;
    }      
`;