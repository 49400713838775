import React, { useState } from "react";
import { Container } from './CheckBoxStyles';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography
  } from '@mui/material';
const CheckBox = ({label, onChange, checked, margin=false}) => {
    console.log('margin', margin);
    return (
        <FormGroup>
            <FormControlLabel 
                control={<Checkbox defaultChecked  
                    checked = {checked}
                    onChange={onChange}
                    labelStyle={{color: 'black'}}
                    iconStyle={{fill: 'var(--btn-primary)'}}
                    inputStyle={{color:'black'}}
                    style={{color:'var(--btn-primary)'}} 
                    />
                } 
                label={<Typography style={{ color: 'var(--black)' , marginRight: `${margin == true? "30px" : "0px"}` }}>{label}</Typography>} 
                />
        </FormGroup>
    )
}

export default CheckBox