import React, { useEffect, useState, Fragment } from 'react';
import LayoutStyles from './LayoutStyles';
import LayoutMobileStyles from './LayoutMobileStyles';
import ServerList from '../ServerList/ServerList';
import ServerName from '../ServerName/ServerName';
import MenuInfo from '../MenuInfo/MenuInfo';
import UserInfo from '../UserInfo/UserInfo';
import MenuList from '../MenuList/MenuList';
import MobileBottom from '../MobileBottom/MobileBottom';

const Layout = ({ children, menu = "query" }) => {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {            
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <Fragment>
            {screenSize.width >= 767 && <LayoutStyles>
                <ServerList />
                <ServerName />
                <MenuInfo menu={menu} />
                <MenuList menu={menu} />
                <UserInfo />
                {children}
            </LayoutStyles>}
            {screenSize.width < 767 && <LayoutMobileStyles>
                <ServerList />
                <ServerName />
                {children}
                <MobileBottom menu={menu}/>
            </LayoutMobileStyles>}
        </Fragment>
    )
}

export default Layout