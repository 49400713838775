import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Stack
} from '@mui/material';
import BorderButton from '../../Common/Buttons/BorderButton';
import OutlineButton from '../../Common/OutlineButton/Button';
import { Container } from './DeleteStyles';

const DeleteModal = ({
    open,
    handleCloseDeleteModdal,
    onCancel,
    onDelete
  }) => {
    useEffect(() => {
      console.log('open:',open)
  
    }, []);

  return (
    
    <Modal
        open={open}
        onClose={handleCloseDeleteModdal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
          background: "rgba(0, 0, 0)",
          opacity:"0.85"
          }
        }}
    >
        <Container>
            <Box sx={{display:'flex',justifyContent:'left'}}>
                <Typography id="modal-modal-title"
                    sx={{mt:2}}
                >
                    {'Are you sure you want to delete this query?'}                     
                </Typography>
            </Box>
            <Stack
              direction="row"
              justifyContent="right"              
              spacing={2}
              sx={{mt: 2, marginTop: "20px"}}
            >
              <OutlineButton
                  variant="outlined" 
                  onClick={onCancel }
                  name={'Cancel'}    
              />
              <BorderButton
                  onClick={onDelete }
                  name={'Delete'}  
              />
          </Stack> 
        </Container>
    </Modal>
  )
}

export default DeleteModal