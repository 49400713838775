import React from 'react'
import { Container, HashtagIcon, Title, Separator, Description} from './MenuInfoStyles'

const MenuInfo = ({menu}) => {
  return (
    <Container>        
        <Title>{menu == "query" ? "Query" : "Bot Config"}</Title>
        {/* <Separator /> */}
        {/* <Description>Canal aberto para conversas</Description> */}
    </Container>
  )
}

export default MenuInfo