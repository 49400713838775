import styled from "styled-components";

export const Button = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    border-radius: 50%;
    color: white;        
    > img{
        width: 24px;
        height: 24px;
    }    
    background-color: ${'var(--primary)'};
`;