import React from 'react';
import { Container } from './ButtonStyles';

const Button = ({name,selected, onClick}) => {

  return (
      <Container onClick={onClick} className={selected ? 'active':''}>
          <div>              
              <span>{name}</span>
          </div>
      </Container>
  )
}

export default Button