import styled  from 'styled-components';

export const Container = styled.div`
    grid-area: CD;
    display:flex;
    flex-direction: column;
    // justify-content: space-between;
    padding:20px;
    background-color: var(--primary);
    .parentDisable{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        height: 100%;
        width: 100%;
        // background: #666;
        // opacity: 0.8;
    }
    .overlay-box {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        color: white; background: #666666; opacity: .8;
        z-index: 1000;
    }

    @media only screen and (max-width: 768px) {        
        padding: 10px;
    }
`;
