
import React, {useEffect} from 'react';
import { Container, Title, WelcomeText, ImageSection, ButtonSection } from "./ViewLoginStyles"
import { DISCORD_AUTH_REDIRECT, DISCORD_CLIENT_ID } from "../../Services/Config"
import Button from '../../Components/Common/Buttons/Button';
const ViewLogin = (props) => {

    useEffect(() => {                
        if (props.isAuth){
            window.location.href = '/';     
        }
    }, [])

    const onUserLogin = () => {        
        const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&permissions=8&redirect_uri=${encodeURIComponent(DISCORD_AUTH_REDIRECT)}&response_type=token&scope=bot+applications.commands+identify+guilds`;
//        "https://discord.com/api/oauth2/authorize?client_id=1117628973472555078&permissions=8&redirect_uri=https%3A%2F%2Fdiscord-plugin-dashboard.vercel.app%2Fapi%2Fdiscord%2Fauth&response_type=code&scope=identify%20guilds%20bot"
        // Redirect the user to the authorization URL
        window.location.href = authUrl;
    };

    return (
        <Container>
            <Title>
                <img src="./bot-title.png" />                
            </Title>
            <WelcomeText>
                <img src="./sharering_icon_white.png" />
                <span>Powered by ShareRing</span>
            </WelcomeText>
            <ImageSection>
                <img src="./bot_icon.png" />
            </ImageSection>
            <ButtonSection>
                <Button
                        onClick={onUserLogin}
                        name={'Login with Discord'}    
                    />
            </ButtonSection>
        </Container>
    )
}

export default ViewLogin