import styled from "styled-components";
import {Hashtag} from "styled-icons/heroicons-outline";

export const Container = styled.div`    
    display: flex;
    position: fixed;    
    bottom: 0;
    width: 100%;
    align-items:center;    
    background-color: var(--black);
    box-shadow: rgba(0,0,0,0.2) 0 1px 0 0;
    z-index: 2;
`;