import styled from "styled-components"

// SL - Server list
// SN - Sever name
// CN - Channel name
// CI - Channel Info
// UL - User List

const LayoutMobileStyles = styled.div`
    display: grid;
    grid-template-columns: 61px auto;
    grid-template-rows: 46px auto;
    grid-template-areas:
        'SL CI'        
        'SL CD'        
    ;
    height: 100vh;
`; 
export default LayoutMobileStyles;