import styled  from 'styled-components';

export const Container = styled.div`    
    display:flex;
    flex-direction: column;    
    background-color: #0E113A;
    width: 100%;
    height: 100%;
`;

export const Title = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #0E113A;    
    margin: 100px auto 20px auto;
    > img {                    
            height: 40px;      
            margin-right: 10px;  
    }
    > span {        
        font-size: 30px;
        color: white;
    }
`;

export const WelcomeText = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #0E113A;    
    margin: 20px auto 20px auto;
    > img {                    
            height: 24px;      
            margin-right: 10px;  
    }
    > span {        
        font-size: 24px;
        color: white;
    }
`;

export const ImageSection = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;        
    margin: 20px auto;
    > img {        
        width: 300px;
    }
`;

export const ButtonSection = styled.div`    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px auto;    
`;