import React, { useState } from "react";
import { Container } from './SelectorConfigStyles';
import {
    Select,
    MenuItem,
    styled
  } from '@mui/material';

const SelectWrapper = styled(Select)(()=>({
    '& .MuiMenu-paper': {
        borderRadius:'0 !important'
    }
}))  
const SelectorConfig = ({selectedOption,handleOptionChange,width, options,mode}) => {

    return (
        <Container>        
        <SelectWrapper
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ 
            width: "100%",
            color: 'var(--white)' ,            
            borderRadius:"0px",
            borderColor: 'var(--white)' ,           
            border: "1px solid" 
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label',MenuProps: {
                borderRadius:0,                
                MenuListProps: {
                    sx: {                        
                        padding:0,
                        "& .Mui-selected": {                            
                            color:'var(--white)'
                        },                        
                    }
                }
            } }}
            >
        {!!options?.length &&
            options.map(({ label, value }) => (
                <MenuItem sx={{ color: 'var(--white)', borderRadius: 0 }} key={value} value={value}>
                {label}
                </MenuItem>
            ))}
        </SelectWrapper>
    </Container>
    )
}

export default SelectorConfig