import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: var(--white);
    transition: background-color .2s;
    cursor: pointer;
    width: fit-content;
    >div{
        display:flex;
        align-items: center;
    }

    >div span{
        font-size:15px;
        color: var(--btn-primary);
    }
    & .iconsRight{
        display:none;
    }
    &.active, &:hover{
        background-color: #eee;

        > div span{
            color: var(--btn-primary);
        }

        & .iconsRight{
            display: inline;
        }
    }
    &.disabled{
        background-color: var(--btn-hover);
        cursor: not-allowed ;
        pointer-events: none;
        opacity:0.6;
        > div span{
            color: #eee;
        }
    }
`;